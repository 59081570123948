import { CustomSelect } from './CustomSelect';
import { CustomRadioGroup } from './CustomRadioGroup';
import { CustomCheckBox } from './CustomCheckBox';
import { CustomTextInput } from './CustomInput';
import { InputProps, Opt } from './getInputs';
import CheckboxGroup from './checkboxgroup/CheckboxGroup';
import { CustomTextArea } from './CustomTextArea';
import CustomMultiSelect from './CustomMultiSelect';
import { CustomFileUpload } from './CustomFileUpload';

export const CustomInputField = ({ ...inputs }: InputProps) => {
  const { name, type, value, minDate, maxDate, onChange, ...props }: any = inputs;
  return (
    <>
      {(() => {
        switch (type) {
          case 'select':
            return (
              <CustomSelect
                key={name}
                label={props.label!}
                name={name}
                options={props.options!}
                onChange={onChange}
                readOnly={props.readOnly}
              />
            );

          case 'radio-group':
            return (
              <CustomRadioGroup
                label={props.label!}
                name={name}
                options={props.options!}
                key={name}
              />
            );

          case 'checkbox':
            return (
              <CustomCheckBox label={props.label!} key={name} name={name} isChecked={props?.isChecked} onChange={onChange} readOnly={props.readOnly} />
            );

          case 'textarea':
            return (
              <CustomTextArea
                label={props.label!}
                key={name}
                name={name}
                id={''}
                type={''}
              />
            );

          case 'checkboxgroup':
            return (
              <CheckboxGroup name={name} label={props.label}>
                {props.options.map((option: Opt) => (
                  <CheckboxGroup.Item
                    name={name}
                    label={option.desc}
                    value={option.value as any}
                    disabled={props.disabled}
                  />
                ))}
              </CheckboxGroup>
            );
          case 'multiselect':
            return (
              <CustomMultiSelect
                key={name}
                label={props.label!}
                name={name}
                options={props.options!}
              />
            );
          case 'file':
            return (
              <CustomFileUpload
                key={name}
                label={props.label!}
                name={name}
                type={type}
                options={props.options!}
                onChange={onChange}
                id={props.id}

              />
            );
          default:
            return (
              <CustomTextInput
                id={name}
                name={name}
                placeholder={props.placeholder}
                type={type}
                label={props.label}
                readOnly={props.readOnly}
                className={props.className}
                keyPress={props.keyPress}
                min={minDate}
                max={maxDate}
                onChange={onChange}
                note={props.note}
              />
            );

        }
      })()}
    </>
  );
};
