const initialState: any = {
    instituteId: null,
    error: null
}

type SelectedInstituteAction = {
    type: string,
    payload: any
};

const SelectedInstituteReducer = (state = initialState, action: SelectedInstituteAction) => {
    switch (action.type) {
        case 'SELECTED_INSTITUTE_SUCCESS':
            return {
                ...state,
                instituteId: action.payload,
                error: null
            }
        case 'SELECTED_INSTITUTE_FAILURE':
            return {
                ...state,
                instituteId: null,
                error: action.payload
            }

        default:
            return state;
    }
}

export default SelectedInstituteReducer