import './assets/css/App.css';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import App from './App';
import { Provider } from 'react-redux';
import Store from 'store/Store';
import { Suspense } from 'react';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={Store}>
    <Suspense fallback={<div> Loading....</div>}>
      <HashRouter>
        <App />
      </HashRouter>
    </Suspense>,
  </Provider>
);
