import instituteService from "services/InstituteService";

export const GetBranchesData = () => async (dispatch: any) => {
    const masterID = localStorage.getItem('instituteMasterID')

    try {
        const branchData = await instituteService.getAllBranches(masterID)
        console.log("branchData", branchData)
        dispatch({ type: 'BRANCH_SUCCESS', payload: branchData.data })
    } catch (error: any) {
        dispatch({ type: 'BRANCH_FAILURE', payload: error.message })
    }
}