import { Navigate, useOutlet } from 'react-router-dom';
const ProtectedRoute = () => {
  const auth = localStorage.getItem('employeemasterID');
  const outlet = useOutlet();
  if (!auth) {
    return <Navigate to="/" />;
  }
  return <>{outlet}</>;
};

export default ProtectedRoute;
