const initialState: any = {
    userPrivilegesData: [],
    error: null,
};
type UserPrivilegesAction = {
    type: string,
    payload: any
};

const UserPrivilegesReducer = (state = initialState, action: UserPrivilegesAction) => {
    switch (action.type) {
        case 'USERPRIVILEGES_SUCCESS':
            return {
                ...state,
                userPrivilegesData: action.payload,
                error: null,
            };
        case 'USERPRIVILEGES_FAILURE':
            return {
                ...state,
                userPrivilegesData: null,
                error: action.payload,
            };
        default:
            return state;
    }
}
export default UserPrivilegesReducer;