import {
    Button,
    Flex,
    FormLabel,
    Input,
    Select,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { ErrorMessage, useField } from 'formik';
import { FaUpload } from 'react-icons/fa';
interface Props {
    options: Opt[];
    label: any;
    name: string;
    mb?: string;
    onChange?: any;
    [x: string]: any;
}
type Opt = { value: string | number; desc: string };
export const CustomFileUpload = ({ label, extra, value, ...props }: Props) => {
    const [field] = useField(props);
    // const handleFileUpload = () => {
    //     document.getElementsByName(props.name)?.[0].click()
    // };
    const handleDownloadClick = () => {
        const selectedFile = props.onChange.uploadedFiles[props.name]
        if (selectedFile) {
            const downloadURL = URL.createObjectURL(selectedFile);
            const downloadLink = document.createElement('a');
            downloadLink.href = downloadURL;
            downloadLink.download = selectedFile.name;
            downloadLink.click();
            URL.revokeObjectURL(downloadURL);
        }
    };
    return (
        <>
            <Flex direction="column" mb={props.mb ? props.mb : '20px'}>
                <FormLabel
                    fontWeight="bold"
                    color={useColorModeValue('gray.700', 'gray.200')}
                    mb={props.mb}
                >
                    <Flex align="center">
                        <Text className="uploadtext" ml={2}>
                            {label}
                        </Text>
                    </Flex>
                </FormLabel>
                <Input
                    {...field}
                    {...props}
                    variant="outline"
                    value={undefined}
                    onKeyPress={props.keyPress}
                    readOnly={props.readOnly}
                    className='hidden'
                    id={props.id}
                    onChange={props.onChange.chgHandler ? (evt) => {
                        props.onChange?.chgHandler(evt, props.onChange?.setFieldValue, props.onChange?.cb)
                    } : field.onChange}
                />
                {/* {field.value && (
                    <>
                        Selected File: {field.value.split(/[\\]/).at(-1)}
                        <Button style={{ display: 'flex', flexDirection: 'column', width: '100px', backgroundColor: '#190793', color: 'white' }} onClick={() => handleDownloadClick()}   >Download</Button>
                    </>
                )} */}
                <ErrorMessage name={props.name} component="span" className="error" />
            </Flex>
        </>
    );
};
