import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Link,
  SimpleGrid,
  color,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { getInputs } from 'components/dynamicform';
import { CustomInputField } from 'components/dynamicform/CustomInputField';
import { Form, Formik, FormikBag, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import CommonToast from 'views/common/Toast';
import { InstitutionFormData } from '../variables/InstitutionFormData';
import AuthCentered from 'layouts/auth/variants/Centered';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dropdown } from 'store/actions/DropDownActions';
import { FaTimesCircle } from 'react-icons/fa';
import { FaTrash, FaUpload } from 'react-icons/fa';
import CommonServices from 'services/CommonService';
import instituteService from 'services/InstituteService';
import StudentImgUpload from '../../../../assets/svg/StudentPhotoUpload.svg'
import { Guid } from 'guid-typescript';


const InstitutionForm = ({ dropDownsetData, dropDownAction, isEditing }: any) => {
  const [documentIds, setDocumentIds] = useState([]);
  const loc = useLocation();
  const [loading, setLoading] = useState(false);
  const textColor = useColorModeValue('navy.700', 'white');
  const [docMappings, setDocumentMappings] = useState({} as any);
  const [uploadedFiles, setUploadedFiles] = useState({} as any);
  const navigate = useNavigate();
  const [instForm, setInstForm] = useState(false);
  const dropDownSet = async () => {
    if (!dropDownsetData['AffiliatedTo']) await dropDownAction('AffiliatedTo');
    if (!dropDownsetData['Curriculum']) await dropDownAction('Curriculum');
    if (!dropDownsetData['Accreditation'])
      await dropDownAction('Accreditation');
    if (!dropDownsetData['InstituteType']) await dropDownAction('InstituteType');
    if (!dropDownsetData['BuildingType']) await dropDownAction('BuildingType');
    if (!dropDownsetData['SchoolOwnerShipDetails']) await dropDownAction('SchoolOwnerShipDetails');
    if (!dropDownsetData['FileType']) await dropDownAction('FileType');
  };
  useEffect(() => {
    if (loc?.state) {
      const { instituteTypeID, instituteName, contactNumber, email, societyName } = loc.state
      setInitValues({
        ...initialValues,
        instituteTypeID, instituteName, contactNumber, email, societyName
      });
    }
    dropDownSet();
    setInstForm(true);
    console.log("uploadedFiles", uploadedFiles)
  }, []);
  const [initValues, setInitValues]: any = useState({});
  const isReadonly = true
  const showSocietyName = true
  const { initialValues, inputs, validationSchema } = getInputs(
    InstitutionFormData(dropDownsetData, isReadonly, showSocietyName).InstitutionForm,
  );
  const handleFileUpload = (itemObj: any) => {
    document.getElementsByName(itemObj.name)?.[0].click();
  };
  const [previewUrlLogo, setPreviewUrlLogo] = useState('');

  const handleLogoChange = (event: any, id: any) => {
    const file = event.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      CommonToast({
        description: 'File size exceeds the limit (5 MB)',
        status: 'error',
      });
      return
    }
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewUrlLogo(reader.result as any);
    };
    reader.readAsDataURL(file);
    documentUploadFn(event, id)
  };

  const handleUpload = (id: any) => {
    let domEle = document.getElementById(id);
    domEle?.click();
  };
  const [selectedLogoDocId, setSelectedLogoDocId] = useState('');
  const filetypeOptions =
    dropDownsetData &&
    dropDownsetData?.FileType &&
    dropDownsetData?.FileType?.map((item: any) => ({
      value: item?.dropdownSetID,
      desc: item?.dropdownValue,
    }));

  const documentUploadFn = (evt: any, id: any, formikFn?: Function, item?: any) => {
    const file = evt.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      CommonToast({
        description: 'File size exceeds the limit (5 MB)',
        status: 'error',
      });
      return
    }
    let fileExt = file.name.split('.').pop();
    let fileTypeID = filetypeOptions?.find((x: any) => x?.desc?.toLowerCase().includes(fileExt?.toLowerCase()))?.value;
    let fileUploadFD = new FormData();
    let payload = {
      documentCategoryID: parseInt(id),
      documentName: file?.name,
      fileSize: file?.size.toString(),
      fileTypeID: fileTypeID,
      userID: localStorage.getItem('userID'),
    }
    fileUploadFD.append('data', JSON.stringify(payload));
    fileUploadFD.append('files', file);
    const ele: any = document.getElementById(id);
    CommonServices.uploadDocument(fileUploadFD).then((res: any) => {
      if (res?.status == 200) {
        if (Guid.parse(res.data.docID).isEmpty()) {
          CommonToast({
            description: 'Upload Failed, Please try again',
            status: 'error',
          });
          ele.value = ''
          return
        }
        if (formikFn) {
          formikFn(item?.name, res?.data?.docID)
        }
        else {
          setSelectedLogoDocId(res?.data?.docID)
        }
        setDocumentIds([...documentIds, res?.data?.docID])
        callbackFnforFiles(evt, id)
        ele.value = ''
      }
    })
  }

  const callbackFnforFiles = (evt: any, id: string) => {
    const { target } = evt;
    setDocumentMappings({ ...docMappings, [target.id]: { ...docMappings[target.id], name: target.files?.[0].name, file: target.files?.[0], categoryId: id } })
  }

  const onDelete = (itemObj?: any, formFn?: Function, docObj?: any, fieldMeta?: Function) => {
    let docId: any = (fieldMeta && fieldMeta(itemObj?.name).value) || selectedLogoDocId
    let payload = {
      documentID: docId,
      userID: localStorage.getItem('userID'),
    }
    CommonServices.deleteDocument(payload).then((res: any) => {
      if (res) {
        let docIds = documentIds.filter((id: any) => id != docId);
        setDocumentIds([...docIds])
        console.log("docIds", docIds)
        if (itemObj) {
          const fileNames = { ...docMappings };
          delete fileNames[itemObj.id].name;
          delete fileNames[itemObj.id].url;
          formFn(itemObj.name, null)
          setDocumentMappings(fileNames)
        }
      }
    })

  }

  const submitHandler = async (values: any, actions: FormikHelpers<any>) => {
    if (previewUrlLogo) {
      const currentYear = new Date().getFullYear()
      if (parseInt(values?.yearOfEstablishment) > currentYear || parseInt(values?.yearOfEstablishment) < 1715) {
        const errorMessage = 'Please enter a year between 1715 and current year';
        actions.setFieldError('yearOfEstablishment', errorMessage);
        return
      }
      if (parseInt(values?.studentStrengthPY) < 400) {
        const errorMessage = 'Student strength should be greater than 400';
        actions.setFieldError('studentStrengthPY', errorMessage);
        return
      }
      else if (parseInt(values?.studentStrengthCY) < 400) {
        const errorMessage = 'Student strength should be greater than 400';
        actions.setFieldError('studentStrengthCY', errorMessage);
        return
      }

      console.log("valuesssss in instsubi", values);
      let a = await actions.validateForm(values);
      console.log('aaaaaaaaaa', a);
      let insertInsPayload: { [key: string]: any } = {
        masterID: localStorage.getItem('instituteMasterID'),
        isMainBranch: true,
        instituteName: values?.instituteName,
        instituteTypeID: parseInt(values?.instituteTypeID) || 0,
        instituteCode: values?.instituteCode,
        buildingTypeID: parseInt(values?.buildingTypeID) || 0,
        schoolOwnerShipDetailID: parseInt(values?.schoolOwnerShipDetailID) || 0,
        affiliatedToID: parseInt(values?.affiliatedToID) || 0,
        universityName: values?.universityName,
        curriculumID: parseInt(values?.curriculumID) || 0,
        accreditationID: parseInt(values?.accreditationID) || 0,
        website: values?.website,
        contactNumber: values?.contactNumber,
        email: values?.email,
        promoterName: values?.promoterName,
        yearOfEstablishment: parseInt(values?.yearOfEstablishment) || 0,
        linkedInURL: values?.linkedInURL,
        facebookURL: values?.facebookURL,
        instagramURL: values?.instagramURL,
        twitterURL: values?.twitterURL,
        studentStrengthPY: parseInt(values?.studentStrengthPY) || 0,
        studentStrengthCY: parseInt(values?.studentStrengthCY) || 0,
        userID: localStorage.getItem('userID'),
        societyName: values?.societyName,
        location: values?.location,
      };
      console.log("insertInsPayload", insertInsPayload)
      setLoading(true);
      instituteService.addorUpdateInstitute(insertInsPayload).then((resp: any) => {
        setLoading(false);
        if (!Guid.parse(resp.data).isEmpty()) {
          let payload =
          {
            entityID: resp.data,
            documentIDs: documentIds,
            createdUserID: localStorage.getItem('userID'),
          }
          CommonServices.addorUpdateEntityDocument(payload).then((res: any) => {
            documentIds.splice(0)
            if (res) {
              instituteService.getInstituteDetailsForLos(resp?.data).then((res: any) => {
              })
            }
          })
          CommonToast({
            title: 'Submitted Successfully',
            status: 'success'
          });
          navigate('/admin/settings/instituteprofile')
        }
      })
    }
    else {
      CommonToast({
        description: 'Please Upload Institute logo',
        status: 'error',
      });
    }
  };
  return (
    <>
      <AuthCentered
        cardTop={{ base: '140px', md: '14vh' }}
        cardBottom={{ base: '50px', lg: 'auto' }}
        mx="0px"
      >
        <Card borderRadius="20px" width={'900px'}>
          {instForm && (
            <>
              <Box>
                <FormLabel
                  color={textColor}
                  fontSize="36px"
                  mb="5px"
                  textAlign={'center'}
                >
                  Institute Profile
                </FormLabel>
              </Box>
              <Flex
                textAlign={'center'}
                justifyContent={'center'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                {previewUrlLogo ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={previewUrlLogo}
                      alt="Preview"
                      style={{ width: '110px', height: '110px', marginTop: '10px' }}
                    />
                    <FaTimesCircle
                      onClick={() => {
                        let a: any = document.getElementById('500');
                        a.value = null
                        setPreviewUrlLogo('');
                        onDelete()
                      }}
                      style={{ marginLeft: '10px', cursor: 'pointer' }}
                    />
                  </div>
                ) : (
                  <>
                    <img src={StudentImgUpload} style={{ width: '110px', height: '110px', marginTop: '10px' }} />

                    <Button
                      size={'xs'}
                      variant={'outline'}
                      borderColor={'#32357C'}
                      margin={'8px 0px'}
                      borderRadius={'29px'}
                      color={'#32357C'}
                      onClick={() => handleUpload('500')}
                    >
                      Upload Institute Logo Photo
                    </Button>
                  </>

                )}

                <FormControl>
                  <Input
                    type="file"
                    id="500"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => handleLogoChange(e, '500')}
                    hidden
                  />
                </FormControl>
              </Flex>

              {Object.keys(initValues).length > 0 && (
                <Formik
                  initialValues={initValues}
                  {...{ validationSchema }}
                  validateOnMount={true}
                  onSubmit={submitHandler}
                  enableReinitialize={true}
                >
                  {({
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    getFieldMeta,
                    values,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Flex direction="column" w="100%">
                        <Grid
                          templateColumns="repeat(1, 1fr)"
                          width="100%"
                          columnGap={10}
                        >
                          {inputs.map((item: any) => {
                            const changeFnObj = {
                              setFieldValue,
                              chgHandler: item.onChange,
                              setFiles: setUploadedFiles,
                              uploadedFiles,
                            };
                            if (item.section === 'institutes') {
                              return (
                                <GridItem>
                                  <CustomInputField {...item} onChange={changeFnObj} />
                                </GridItem>
                              );
                            }
                          })}
                        </Grid>
                        <Grid
                          templateColumns="repeat(2, 1fr)"
                          width="100%"
                          columnGap={10}
                        >
                          {inputs.map((item: any) => {
                            const changeFnObj = {
                              setFieldValue,
                              chgHandler: item.onChange,
                            };
                            if (item.section === 'institute') {
                              return (
                                <GridItem>
                                  <CustomInputField {...item} />
                                </GridItem>
                              );
                            }
                          })}
                        </Grid>
                        <Grid
                          templateColumns="repeat(1, 1fr)"
                          width="100%"
                          columnGap={10}
                          paddingBottom={'15px'}
                        >
                          {inputs.map((item: any) => {
                            const changeFnObj = {
                              setFieldValue,
                              chgHandler: item.onChange,
                            };
                            if (item.section === 'website') {
                              return (
                                <GridItem>
                                  <CustomInputField {...item} />
                                </GridItem>
                              );
                            }
                          })}
                        </Grid>
                        {/* <Grid
                          templateColumns="repeat(1, 1fr)"
                          width="100%"
                          columnGap={10}
                        >
                          <Flex
                            fontSize={'16px'}
                            fontWeight={'600'}
                            paddingTop={'15px'}
                            borderTop={'1px solid #DADADA'}
                          >
                            Address
                          </Flex>
                          {inputs.map((item: any) => {
                            const changeFnObj = {
                              setFieldValue,
                              chgHandler: item.onChange,
                            };
                            if (item.section === 'address') {
                              return (
                                <GridItem>
                                  <CustomInputField {...item} />
                                </GridItem>
                              );
                            }
                          })}
                        </Grid> */}
                        <Grid
                          templateColumns="repeat(3, 1fr)"
                          width="100%"
                          columnGap={10}
                        >
                          {inputs.map((item: any) => {
                            const changeFnObj = {
                              setFieldValue,
                              chgHandler: item.onChange,
                            };
                            if (item.section === 'state') {
                              return (
                                <GridItem>
                                  <CustomInputField {...item} />
                                </GridItem>
                              );
                            }
                          })}
                        </Grid>
                        <Grid
                          templateColumns="repeat(2, 1fr)"
                          width="100%"
                          columnGap={10}
                          paddingBottom={'15px'}
                        >
                          {inputs.map((item: any) => {
                            const changeFnObj = {
                              setFieldValue,
                              chgHandler: item.onChange,
                            };
                            if (item.section === 'pincode') {
                              return (
                                <GridItem>
                                  <CustomInputField {...item} />
                                </GridItem>
                              );
                            }
                          })}
                        </Grid>
                        <Grid
                          templateColumns="repeat(1, 1fr)"
                          width="100%"
                          columnGap={10}
                          paddingBottom={'15px'}
                          borderBottom={'1px solid #dadada'}
                        >
                          <Flex
                            fontSize={'16px'}
                            fontWeight={'600'}
                            paddingTop={'15px'}
                            borderTop={'1px solid #DADADA'}
                          >
                            Social Media
                          </Flex>
                          {inputs.map((item: any) => {
                            const changeFnObj = {
                              setFieldValue,
                              chgHandler: item.onChange,
                            };
                            if (item.section === 'socialmedia') {
                              return (
                                <GridItem>
                                  <CustomInputField {...item} />
                                </GridItem>
                              );
                            }
                          })}
                        </Grid>
                      </Flex>
                      {/* <Flex
                        fontSize={'16px'}
                        fontWeight={'600'}
                        borderTop={'1px solid #DADADA'}
                        paddingTop={'15px'}
                      >
                        Required Documents
                      </Flex> */}
                      <Flex py={3}>
                        <Grid
                          templateColumns="repeat(2, 1fr)"
                          width="100%"
                          columnGap={20}
                        >
                          <>
                          </>
                          <GridItem>
                            <Grid
                              templateColumns="repeat(2, 1fr)"
                              width="100%"
                              columnGap={10}
                              px={5}
                            >
                            </Grid>
                          </GridItem>
                        </Grid>
                      </Flex>

                      <Flex
                        fontSize={'16px'}
                        fontWeight={'600'}
                        borderTop={'1px solid #DADADA'}
                        paddingTop={'15px'}
                      >
                        Required Documents
                      </Flex>
                      <Flex py={3}>
                        <SimpleGrid columns={2} gap={20} >
                          <GridItem>
                            {/* <SimpleGrid columns={2} gap={10} px={5}>
                      <GridItem>
                        <Text
                          color={'#4C4C4C'}
                          fontSize={'18px'}
                          fontWeight={500}
                        >
                          Upload
                        </Text>
                      </GridItem>
                      <GridItem><Text color={'#4C4C4C'} fontSize={'18px'} fontWeight={500} >Remove</Text></GridItem>
                    </SimpleGrid> */}
                          </GridItem>
                        </SimpleGrid>
                      </Flex>
                      <SimpleGrid columns={2}>
                        {inputs?.map((item: any) => {
                          const changeFnObj = {
                            chgHandler: (evt: any) => {
                              documentUploadFn(evt, item.id, setFieldValue, item)
                            }
                          };
                          if (item.section === 'fileUpload') {
                            return (
                              <>
                                <GridItem>
                                  <CustomInputField {...item} onChange={changeFnObj} />
                                </GridItem>
                                <GridItem >
                                  {
                                    docMappings[item.id]?.name ?
                                      (
                                        <SimpleGrid columns={2} gap={10}>
                                          <GridItem>
                                            <Link href={docMappings[item.id].url}>
                                              <u>{docMappings[item.id].name}</u>
                                            </Link>
                                          </GridItem>
                                          <GridItem>
                                            {/* <div style={{ marginLeft: 'auto', display: 'inline-flex' }}> */}
                                            <FaTrash
                                              className="trashIcon"
                                              onClick={() => {
                                                onDelete(item, setFieldValue, docMappings[item.id], getFieldMeta);
                                              }}

                                            />
                                          </GridItem>
                                          {/* </div> */}
                                        </SimpleGrid>
                                      ) : (
                                        <FaUpload
                                          onClick={() => {
                                            handleFileUpload(item);
                                          }}
                                          style={{ marginRight: '15px' }}
                                        />
                                      )}
                                </GridItem>
                              </>
                            );
                          }
                        })}
                      </SimpleGrid >

                      <Flex justifyContent={'center'} marginTop={'30px'}>
                        <Button
                          variant="darkBrand"
                          type="submit"
                          padding={'22px 32px'}
                          isLoading={loading}
                          textAlign={'center'}
                          alignItems={'center'}
                          borderRadius={'29px'}
                          loadingText={isEditing === true ? 'Updating' : 'Uploading'}
                          mx={5}
                          isDisabled={isSubmitting}
                        >
                          Submit
                        </Button>
                        <Button
                          variant="outline"
                          type="reset"
                          padding={'22px 32px'}
                          textAlign={'center'}
                          alignItems={'center'}
                          borderRadius={'29px'}
                          color={'#32357C'}
                          borderColor={'#32357C'}
                          mx={5}
                        >
                          Clear
                        </Button>
                      </Flex>
                    </Form>
                  )}
                </Formik>
              )}
            </>
          )}
        </Card>
      </AuthCentered>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  dropDownsetData: state.dropDown.dropdownData,
});
export default connect(mapStateToProps, { dropDownAction: Dropdown })(
  InstitutionForm,
);