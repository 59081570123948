const initialState: any = {
    privilegeData: [],
    error: null,
};

type PrivilegeAction = {
    type: string,
    payload: any
};

const PrivilegeReducer = (state = initialState, action: PrivilegeAction) => {
    switch (action.type) {
        case 'PRIVILEGE_SUCCESS':
            return {
                ...state,
                privilegeData: action.payload,
                error: null,
            };

        case 'PRIVILEGE_FAILURE':
            return {
                ...state,
                privilegeData: null,
                error: action.payload,
            };

        default:
            return state;
    }
}

export default PrivilegeReducer;