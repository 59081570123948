import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import DropDownReducer from './reducers/DropDownReducer';
import PrivilegeReducer from './reducers/PrivilegeReducer';
import UserPrivilegesReducer from './reducers/UserPrivilegesReducer';
import SelectedInstituteReducer from './reducers/SelectedInstituteReducer';
import GetEnquriyByIdReducer from './reducers/GetEnquiryReducer';
import BranchReducer from './reducers/BranchReducer';
import AcademicYearReducer from './reducers/AcademicYearReducer';
import EmployeeInstitutesReducer from './reducers/EmployeeInstitutesReducer';

const middleware = [thunk];
const rootReducer = combineReducers({
  dropDown: DropDownReducer,
  privilege: PrivilegeReducer,
  userPrivileges: UserPrivilegesReducer,
  selectedinstituteID: SelectedInstituteReducer,
  selectedEnquiry: GetEnquriyByIdReducer,
  branches: BranchReducer,
  academicYear: AcademicYearReducer,
  employeeInstitutes: EmployeeInstitutesReducer,
});
const Store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);
export default Store;
