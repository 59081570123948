const initialState: any = {
    enquiryId: null,
    error: null
}

type GetEnquiryAction = {
    type: string,
    payload: any
};

const GetEnquriyByIdReducer = (state = initialState, action: GetEnquiryAction) => {
    switch (action.type) {
        case 'ENQUIRY_SUCCESS':
            return {
                ...state,
                enquiryId: action.payload,
                error: null
            }
        case 'ENQUIRY_FAILURE':
            return {
                ...state,
                enquiryId: null,
                error: action.payload
            }

        default:
            return state;
    }
}

export default GetEnquriyByIdReducer