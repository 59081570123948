import { FormLabel, useColorModeValue, Text } from '@chakra-ui/react';
import React from 'react';
import Select from 'react-select';

const CustomMultiSelect = ({
  field,
  form,
  options,
  label,
  extra,
  ...rest
}: any) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <>
      <FormLabel
        display="flex"
        style={{ marginTop: '10px', marginBottom: '0px' }}
        ms="10px"
        fontSize="sm"
        color={textColorPrimary}
        fontWeight="bold"
        _hover={{ cursor: 'pointer' }}
        textTransform={'capitalize'}
      >
        {label}
        <Text fontSize="sm" fontWeight="400" ms="2px" mt={1} mb={1}>
          {extra}
        </Text>
      </FormLabel>
      <Select
        borderRadius="0.375rem"
        {...field}
        {...rest}
        isMulti
        options={options}
      ></Select>
    </>
  );
};

export default CustomMultiSelect;
