const initialState: any = {
    branchData: [],
    error: null
}

type BranchAction = {
    type: string,
    payload: any
}

const BranchReducer = (state = initialState, action: BranchAction) => {
    switch (action.type) {
        case 'BRANCH_SUCCESS':
            return {
                ...state,
                branchData: action.payload,
                error: null
            };
        case 'BRANCH_FAILURE':
            return {
                ...state,
                branchData: null,
                error: action.payload,
            };

        default:
            return state;
    }
}
export default BranchReducer