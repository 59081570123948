import { Environment } from './Environment';
import CommonToast from 'views/common/Toast';
import axiosInterceptor from './axiosInterceptor';
import { Constants } from './Constants';
class CommonService {
  //get dropdown by id
  GetDropdownById(id: string) {
    return axiosInterceptor
      .get(Environment.erpcommonurl + Constants.version1 + `GetDropDownSet?DropDownGroup=${id}`)
      .then((response: any) => {
        return response?.data;
      });
  }

  getAllAcademicYearDetails() {
    return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + 'GetAllAcademicYearDetails').then((response: any) => {
      return response.data;
    })
  }

  getFeeStructure(classID: number, instId: string, academicYearID: number) {
    return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetClassFeeStructure?classInstituteMapID=${classID}&instituteMasterID=${instId}&academicYearID=${academicYearID}`).then((response: any) => {
      return response.data;
    })
  }

  updateFeeStructure(payload: any) {
    return axiosInterceptor.post(Environment.erpinstituteurl + Constants.version1 + `AddorUpdateClassFeeStructure`, payload).then((response: any) => {
      return response.data;
    })
  }


  upsertStudentFamilyDetails(payload: any) {
    return axiosInterceptor.post(Environment.erpinstituteurl + Constants.version1 + `UpsertStudentFamilyDetails`, payload).then((response: any) => {
      return response.data;
    })
  }

  getStudentFamilyDetails(admissionID: string, enquiryID: string) {
    // let qParam = admissionID ? `admissionID=${admissionID}` : `enquiryId=${enquiryID}`
    return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetStudentFamilyDetails?admissionID=${admissionID}&enquiryId=${enquiryID}`).then((response: any) => {
      return response.data;
    })
  }
  addorUpdateSibling(payload: any) {
    return axiosInterceptor.post(Environment.erpinstituteurl + Constants.version1 + `AddorUpdateSibling`, payload).then((response: any) => {
      return response.data;
    })
  }

  getAllSiblings(admID: string) {
    return axiosInterceptor.get(Environment.erpinstituteurl + Constants.version1 + `GetAllSiblings?admissionID=${admID}`).then((response: any) => {
      return response.data;
    })
  }

  uploadDocument(payload: any) {
    return axiosInterceptor.post(Environment.erpcommonurl + Constants.version1 + `UploadDocument`, payload).then((response: any) => {
      return response.data;
    })
  }

  addorUpdateEntityDocument(payload: any) {
    return axiosInterceptor.post(Environment.erpcommonurl + Constants.version1 + `AddorUpdateEntityDocument`, payload).then((response: any) => {
      return response.data;
    })
  }

  deleteDocument(payload: any) {
    return axiosInterceptor.post(Environment.erpcommonurl + Constants.version1 + `DeleteDocument`, payload).then((response: any) => {
      return response.data;
    })
  }

  getEntityDocument(payload: any) {
    return axiosInterceptor.post(Environment.erpcommonurl + Constants.version1 + `GetEntityDocument`, payload).then((response: any) => {
      return response.data;
    })
  }
}

let CommonServices = new CommonService();
export default CommonServices;
