import * as Yup from "yup";
//import { forms } from './forms';

type YupBoolean = Yup.BooleanSchema<boolean | undefined, Yup.AnyObject, boolean | undefined>
type YupString = Yup.StringSchema<string | undefined, Yup.AnyObject, string | undefined>
type YupArray = Yup.ArraySchema<string[] | undefined, Yup.AnyObject | undefined>


export interface InputProps {
    type?: 'text' | 'radio-group' | 'email' | 'password' | 'select' | 'checkbox' | 'checkboxgroup' | 'date' | 'file' | 'textarea' | 'datetime-local' | 'time' | 'multiselect' | 'number'
    name: string
    value: string | number | boolean | string[]
    validations: Validation[]
    placeholder?: string
    typeValue?: 'string' | 'boolean' | 'array'
    label?: any
    options?: Opt[]
    section?: string
    onChange?: any,
    readOnly?: boolean,
    className?: any,
    subSectionName?: string,
    keyPress?: Function,
    minDate?: any;
    from?: any;
    id?: any;
    isChecked?: boolean;
    note?: string;
}

export interface Opt {
    value: string | number
    desc: string
}

export interface Validation {
    type: 'required' | 'isEmail' | 'minLength' | 'isTrue' | 'maxLength' | 'chkgrprequired' | 'matchesTenDigits' | 'isAlphabetic' | 'isNumeric' | 'isAlphabeticspaceallowed' | 'customEmail' | 'passwordMatch' | 'isContact' | 'panValidation' | 'aadhaarValidation'
    value?: string | number | boolean | Array<string>
    message: string
}

const generateValidations = (field: InputProps) => {
    const fieldtype = field.typeValue ? field.typeValue : 'string';
    let schema: any = Yup['string']();
    if (fieldtype === 'array')
        schema = Yup['array']();
    else if (fieldtype === 'boolean')
        schema = Yup['boolean']();

    for (const rule of field.validations) {
        switch (rule.type) {
            case 'isTrue': schema = (schema as YupBoolean).isTrue(rule.message); break;
            case 'isEmail': schema = (schema as YupString).email(rule.message); break;
            case 'customEmail': schema = (schema as YupString).matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/, rule.message); break;
            case 'isContact': schema = (schema as YupString).matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, rule.message); break;
            case 'minLength': schema = (schema as YupString).min(rule?.value as number, rule.message); break;
            case 'maxLength': schema = (schema as YupString).max(rule?.value as number, rule.message); break;
            case 'chkgrprequired': schema = Yup.array().min(rule?.value as number, rule.message); break;
            // .of(Yup.string().required(rule.message)); break;
            case 'matchesTenDigits':
                schema = (schema as YupString).matches(/^\d{10}$/, rule.message);
                break;
            case 'isNumeric':
                schema = schema.test({
                    name: 'isNumeric',
                    message: rule.message,
                    test: (value: string | undefined) => {
                        if (!value) return true; // Allow empty values
                        return /^[0-9]+$/.test(value); // Allow only numeric characters
                    },
                });
                break;
            case 'isAlphabetic':
                schema = (schema as YupString).matches(/^[a-zA-Z]+$/, rule.message);
                break;
            case 'isAlphabeticspaceallowed':
                schema = (schema as YupString).matches(/^[a-zA-Z][a-zA-Z ]*$/, rule.message);
                break;
            case 'passwordMatch': // Custom rule for password matching
                schema = schema.test({
                    name: 'passwordMatch',
                    message: rule.message,
                    test: function (value: string | undefined) {
                        return value === this.resolve(Yup.ref('password'));
                    },
                });
                break;
            //case 'checkboxgrp': schema = (schema as YupArray).length(rule?.value as number, rule.message); break;
            case 'panValidation': schema = (schema as YupString).matches(/^[A-Za-z]{3}[A,a,P,p]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}$/, rule.message); break;
            case 'aadhaarValidation': schema = (schema as YupString).matches(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/, rule.message); break;
            default: schema = schema.required(rule.message); break;
        }
    }

    return schema
}

export const getInputs = (formData: InputProps[]) => {
    let initialValues: { [key: string]: any } = {};
    let validationsFields: { [key: string]: any } = {};
    for (const field of formData) {
        initialValues[field.name] = field.value;
        if (!field.validations) continue;
        const schema = generateValidations(field)
        validationsFields[field.name] = schema;
    }
    return {
        validationSchema: Yup.object({ ...validationsFields }),
        initialValues,
        inputs: formData,
    };

};
