import React from 'react';
import { useCheckboxContext } from './CheckboxContext';
import { Checkbox, Flex, FormLabel, Stack } from '@chakra-ui/react';
import { useField } from 'formik';

type Props = {
  value: any;
  label: string;
  name: string;
  [x: string]: any;
};

export default function CheckboxGroupItem(props: Props) {
  const { label, value } = props;
  const { field, helpers } = useCheckboxContext();
  // const [field] = useField(props);
  const checked = Boolean(
    field.value && field.value.find((_: any) => _ == value),
  );
  return (
    <Flex direction="row" align="center">
      <FormLabel className="label_check">
        <Checkbox
          {...field}
          {...props}
          isChecked={checked}
          colorScheme="brandScheme"
          onChange={() => {
            if (checked) {
              helpers.setValue(field.value.filter((_: any) => _ !== value));
            } else {
              helpers.setValue([...field?.value, value]);
            }
          }}
        >
          {label}
        </Checkbox>
      </FormLabel>
    </Flex>
  );
}
