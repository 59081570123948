// chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import { MdLogout } from 'react-icons/md';
import { connect } from 'react-redux';
// FUNCTIONS

function SidebarContent(props: {
  routes: RoutesType[];
  hovered?: boolean;
  mini?: boolean;
}) {
  const { routes, mini, hovered } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const appid: string = localStorage.getItem('appId');

  const Logout = () => {
    // authService.logout(payload).then(() => {
    localStorage.clear();
    window.location.reload()
    // });
  };
  const size = useBreakpointValue({ base: 'xs', lg: 'xl' })
  // SIDEBAR
  return (

    <Flex direction="column" height="100%" pt="25px" >
      <Brand mini={mini} hovered={hovered} />
      <Stack direction="column" mb="auto" mt="8px">
        <Box
          ps={
            mini === false
              ? '20px'
              : mini === true && hovered === true
                ? '20px'
                : '16px'
          }
          pe={{ md: '16px', '2xl': '1px' }}
          ms={mini && hovered === false ? '-16px' : 'unset'}
        >
          <Links mini={mini} hovered={hovered} routes={routes} />
        </Box>
      </Stack>
      <Box
        ps="20px"
        pe={{ md: '16px', '2xl': '0px' }}
        mt="10px"
        mb={'20px'}
      >
        <Button onClick={onOpen}><MdLogout /> &nbsp; &nbsp; Log Out</Button>
        <Flex flexDirection="column" p="10px">
          <Modal isOpen={isOpen} onClose={onClose} size={size}>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalBody>
                <Text>Are you sure you want to logout?</Text>
              </ModalBody>

              <ModalFooter justifyContent={'space-around'}>
                <Flex >
                  <Button
                    variant="lightBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={onClose}
                    mr={3}
                  >
                    No
                  </Button>
                  <Button
                    variant="lightBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={Logout}
                  >
                    Logout
                  </Button>
                </Flex>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
        {/* <SidebarCard mini={mini} hovered={hovered} /> */}
      </Box>
    </Flex>
  );
}
const mapStateToProps = (state: any) => ({
});
export default connect(mapStateToProps)(SidebarContent);
