// Chakra imports
import { Flex, Select, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';

import { connect } from 'react-redux';

import { useEffect, useState } from 'react';
import { GetSelectedInstitute } from 'store/actions/SelectedInstituteActions';
import instituteService from 'services/InstituteService';
import { useNavigate } from 'react-router';
import { GetUserPrivileges } from 'store/actions/UserPrivilegesActions';
import { GetEmployeeInstitutes } from 'store/actions/EmployeeInstitutesActions';

let employeeInstituteMapActionCalled = false;
let userPrivilegeActionCalled = false;
let mainBranchID: any
export function SidebarBrand(props: { mini: boolean; hovered: boolean, setMini: any, updateInstAction: any, UserPrivilegesStore: any, userPrivilegeAction: any, employeeInstitutesAction: any, employeeInstitutes: any }) {
  const { mini, hovered, updateInstAction, UserPrivilegesStore, userPrivilegeAction, employeeInstitutesAction, employeeInstitutes } = props;


  const [academicYearOptions, setAcademicYearOptions] = useState([]);
  const initialSelectedAcademicYear: any = academicYearOptions[0]?.dropdownSetID;
  const [employeeInstituteOptions, setEmployeeInstituteOptions] = useState([]);

  const [selectedOption, setSelectedOption] = useState(employeeInstituteOptions.length > 0 ? employeeInstituteOptions[0].value : '');
  let branchactionCalled = false

  const navigate = useNavigate();
  const handleOptionChange = (evt: any, toNavigate: boolean) => {
    setSelectedOption(evt.target.value)
    let instid = evt.target.value
    instituteService.setGlobals(instid);
    updateInstAction(instid);
    if (toNavigate) navigate('/admin/settings/instituteprofile')
  }
  const admin = JSON.parse(localStorage.getItem('userdetails')) || {};

  const fname = admin && admin.firstName;
  const lname = admin && admin.lastName;
  const role = admin && admin.roleName;
  const roleID = admin && admin.roleID;

  useEffect(() => {
    // CommonServices.getAllAcademicYearDetails().then((response: any) => {
    //   console.log("res", response)
    //   let options = response?.data?.map((ayoptions: any) => ({
    //     value: ayoptions.academicYearID,
    //     label: ayoptions.academicYear,
    //   }));
    //   setAcademicYearOptions(options);
    // });
    if (UserPrivilegesStore.length == 0 && !userPrivilegeActionCalled) {
      if (roleID) {
        userPrivilegeAction(roleID)
        userPrivilegeActionCalled = true
      }
    }
    if (employeeInstitutes.length == 0 && !employeeInstituteMapActionCalled) {
      employeeInstituteMapActionCalled = true
      employeeInstitutesAction()
    }

    mainBranchID = employeeInstitutes.find((element: any) => element.isMainBranch == true)?.masterID
    let vals: Array<any> = [];
    vals = employeeInstitutes?.map((x: any) => ({ value: x?.masterID, desc: x?.instituteName }))
    setEmployeeInstituteOptions(vals)

    handleOptionChange({
      target: {
        value: vals[0]?.value
      }
    }, false)

  }, [employeeInstitutes])


  return (

    <Flex alignItems="center" flexDirection="column">
      <Text fontSize={'18px'} fontWeight="800" display={
        mini === false
          ? 'block'
          : mini === true && hovered === true
            ? 'block'
            : 'none'
      }>
        {fname} {lname}
      </Text>

      <Text fontSize={'18px'} fontWeight="800" display={
        mini === false
          ? 'none'
          : mini === true && hovered === true
            ? 'none'
            : 'block'
      }>
        {fname?.charAt(0)} {lname?.charAt(0)}
      </Text>

      <Text fontSize={'12px'} fontWeight="800" mb="2" display={
        mini === false
          ? 'block'
          : mini === true && hovered === true
            ? 'block'
            : 'none'
      }>
        {role}
      </Text>


      <Text fontSize={'18px'} fontWeight="800" display={
        mini === false
          ? 'none'
          : mini === true && hovered === true
            ? 'none'
            : 'block'
      }>
        {role?.charAt(0)}
      </Text>


      <Flex alignItems="center" mt={4}>
        <Select
          display={
            mini === false
              ? 'none'
              : mini === true && hovered === true
                ? 'none'
                : 'block'
          }>

        </Select>
        <Select
          display={
            mini === false
              ? 'none'
              : mini === true && hovered === true
                ? 'none'
                : 'block'
          }>

        </Select>


        <Select
          borderRadius="5px"
          borderColor="#32357C"
          value={selectedOption}
          fontSize="12px"
          padding={3}
          isDisabled={employeeInstituteOptions.length === 1}
          onChange={(evt: any) => {
            handleOptionChange(evt, true);
          }}
          display={
            mini === false
              ? 'block'
              : mini === true && hovered === true
                ? 'block'
                : 'none'
          }>
          {employeeInstituteOptions.map((empInst) => (
            <option key={empInst.value} value={empInst.value}>
              {empInst.value === mainBranchID
                ? `${empInst.desc.length > 40 ? `${empInst.desc.slice(0, 40)}...` : empInst.desc} (Main Branch)`
                : empInst.desc.length > 40 ? `${empInst.desc.slice(0, 40)}...` : empInst.desc}
            </option>
          ))}
        </Select>

        {/* <Select
          value={initialSelectedAcademicYear}
          background="#32357C"
          color="#fff"
          display={
            mini === false
              ? 'block'
              : mini === true && hovered === true
                ? 'block'
                : 'none'
          }>
          {academicYearOptions.map((acyear) => (
            <option key={acyear.value} value={acyear.value}>
              {acyear.label}
            </option>
          ))}
        </Select> */}
      </Flex>


      {/* <HSeparator mb="20px" /> */}
    </Flex>
  );
}

const mapStateToProps = (state: any) => ({
  UserPrivilegesStore: state.userPrivileges.userPrivilegesData,
  employeeInstitutes: state.employeeInstitutes.employeeInstitutesData
});

export default connect(mapStateToProps, {
  updateInstAction: GetSelectedInstitute,
  userPrivilegeAction: GetUserPrivileges, employeeInstitutesAction: GetEmployeeInstitutes
})(SidebarBrand);;
