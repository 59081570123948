import instituteService from "services/InstituteService";

export const GetEmployeeInstitutes = () => async (dispatch: any) => {
    const masterID = localStorage.getItem('employeemasterID')

    try {
        const employeeInstitutesData = await instituteService.getEmployeeInstituteMapByEmployeeID(masterID)
        console.log("employeeInstitutesData", employeeInstitutesData)
        dispatch({ type: 'EMPLOYEEINSTITUTE_SUCCESS', payload: employeeInstitutesData.data })
    } catch (error: any) {
        dispatch({ type: 'EMPLOYEEINSTITUTE_FAILURE', payload: error.message })
    }
}