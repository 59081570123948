import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from 'axios';
import CommonToast from 'views/common/Toast';
const axiosInterceptor: AxiosInstance = axios.create({});
axiosInterceptor.interceptors.request.use(
  async (config: InternalAxiosRequestConfig<any>) => {
    let admin = JSON.parse(localStorage.getItem('admin')) || {};
    let { userID, token } = admin;
    if (config.headers) {
      config.headers.set('Userid', userID);
      config.headers.appId = 'SchoolERP';
      let curTime = new Date().valueOf();
      let tokenExp = parseInt(atob(localStorage.getItem(btoa('tokenTime'))));
      // if (tokenExp - curTime <= 15) {
      //   let refreshVal = await authService.getRefreshToken();
      //   let userData = { ...admin, token: refreshVal };
      //   localStorage.setItem('admin', JSON.stringify(userData));
      //   let tokenTime = new Date().valueOf() + 60 * 60 * 1000;
      //   localStorage.setItem(btoa('tokenTime'), btoa(tokenTime.toString()));
      //   config.headers.Authorization =
      //     'Bearer ' + refreshVal?.accessToken || '';
      // } else {
      //   config.headers.Authorization = 'Bearer ' + token?.accessToken || '';
      //   config.headers.params = '';
      // }
    }
    return config;
  },
);
axiosInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: any) => {
    console.log("error", error)
    if (error.response) {
      if (error.response.status === 404) {
        CommonToast({
          title: 'Error',
          description: 'Requested resource not found',
          status: 'error',
        });
      } else if (error.response.status === 401) {
        // localStorage.clear();
        // window.location.reload()
        CommonToast({
          title: 'Error',
          description: 'Authorization Failed',
          status: 'error',
        });
      }
      else if (error.response.status === 500) {
        CommonToast({
          title: 'Error',
          description: 'Failed to fetch !',
          status: 'error',
        });
      }
      else if (error.response.data.status === 502) {
        CommonToast({
          title: 'Error',
          description: error.response.data.Message,
          status: 'error',
        });
      }
      else {
        CommonToast({
          title: 'Error',
          description: error.response.data.message,
          status: 'error',
        });
      }
    } else if (error.request) {
      CommonToast({
        title: 'Error',
        description: 'No response received from the server',
        status: 'error',
      });
    } else {
      CommonToast({
        title: 'Error',
        description: error.message,
        status: 'error',
      });
    }
    return {};
  },
);
export default axiosInterceptor;
