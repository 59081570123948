import { createStandaloneToast } from "@chakra-ui/react";

const CommonToast = ({ title, description, status }: any) => {
    const { toast } = createStandaloneToast();
    toast({
        title: title,
        description: description,
        status: status,
        duration: 3000,
        isClosable: true,
        position: "top-right",
    })
};

export default CommonToast;
