import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import { RiEyeCloseLine } from 'react-icons/ri';
import * as Yup from 'yup';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { useState } from 'react';
import AuthCentered from 'layouts/auth/variants/Centered';
import Card from 'components/card/Card';
import { useLocation, useNavigate } from 'react-router-dom';
import { VSeparator } from 'components/separator/Separator';
import instituteService from 'services/InstituteService';
import CommonServices from 'services/CommonService';


const CreateUser = () => {
  const textColor = useColorModeValue('navy.700', 'white');
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const textColorSecondary = 'gray.400';
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [genderOptions, setGenderOptions] = useState([]);
  const loc = useLocation();
  const [initValues, setInitValues] = useState(null);
  useEffect(() => {
    const { email } = loc.state || { email: '' };
    setInitValues({
      firstName: '', lastName: '', contactNumber: '', alternateContactNumber: '', password: '', genderID: '', email
    });
    console.log("email", email)
    CommonServices.GetDropdownById('Gender').then((response: any) => {
      console.log('respponssssss', response)
      let options = response?.map((gender: any) => ({
        value: gender.dropdownSetID,
        label: gender.dropdownValue,
      }));
      setGenderOptions(options);
    });
  }, []);
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First Name is required')
      .min(3, 'First Name should atleast have 3 characters'),
    lastName: Yup.string()
      .required('Last Name is required')
      .min(3, 'Last Name should atleast have 3 characters'),
    contactNumber: Yup.string()
      .required('Contact Number is required')
      .min(10, 'Contact Number should be 10 digits only')
      .max(10, 'Contact Number should be 10 digits only')
      .matches(phoneRegExp, 'Contact Number is not valid'),
    alternateContactNumber: Yup.string().min(10, 'Alternate Contact Number should be 10 digits only')
      .max(10, 'Alternate Contact Number should be 10 digits only')
      .matches(phoneRegExp, 'Alternate Contact Number is not valid'),
    genderID: Yup.string().required('Gender is required'),
    password: Yup.string().required('Password is required').min(8, "You must enter at least 8 characters."),
  });
  const acceptNumbers = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const digitRegex = /^\d$/;
    if (!digitRegex.test(e.key)) {
      e.preventDefault();
    }
    const input = e.target as HTMLInputElement;
    if (input.value.length >= 10) {
      e.preventDefault();
    }
  };
  const acceptCharacters = (evt: KeyboardEvent) => {
    if (!/^[a-zA-Z\s]{1}[ a-zA-Z\s]*$/.test(evt.key)) {
      evt.preventDefault();
    }
  };
  // const [value, setValue] = React.useState("");
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setValue(event.target.value);
  // };
  const handleSubmit = (values: any, actions: any) => {
    console.log("valuessssssss", values)
    let payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      contactNumber: values.contactNumber,
      alternateContactNumber: values.alternateContactNumber ? values.alternateContactNumber : '',
      parentInstituteMasterID: localStorage.getItem('instituteMasterID'),
      genderID: parseInt(values?.genderID),
      userID: "SYSTEM",
      password: values?.password,
      instituteEmail: values?.email
    }
    instituteService.onBoardEmployee(payload).then((res: any) => {
      console.log("resss", res)
      if (res?.status == 200) {
        localStorage.setItem('employeemasterID', res.data.employeeMasterID);
        localStorage.setItem('userID', res.data.userID);
        localStorage.setItem('userdetails', JSON.stringify(res.data))

        let masterID = localStorage.getItem('instituteMasterID')
        console.log("masterID", masterID)
        instituteService.getInstituteById(masterID).then((res: any) => {
          if (res?.status === 200) {
            const { instituteTypeID, instituteName, contactNumber, email, societyName } = res.data || {}
            console.log("res.data", res.data)
            console.log("instituteTypeID", instituteTypeID)
            navigate(`/institutions`, {
              state: {
                instituteTypeID, instituteName, contactNumber, email, societyName
              }
            })
          }
        })
      }
    })
  };
  return (
    <AuthCentered
      cardTop={{ base: '140px', md: '14vh' }}
      cardBottom={{ base: '50px', lg: 'auto' }}
      mx="0px"
    >
      <Card padding={'0px'} borderRadius="20px" width={'900px'}>
        <Box>
          <FormLabel
            color={textColor}
            fontSize="36px"
            mb="5px"
            textAlign={'center'}
          >
            User Profile
          </FormLabel>
        </Box>
        {initValues && (
          <Formik
            initialValues={initValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnMount={true}
          >
            {(formik) => {
              return (
                <Form>
                  <Grid
                    templateColumns="repeat(2, 1fr)"
                    width="100%"
                    columnGap={10}
                  >
                    <GridItem>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="bold"
                        color={textColor}
                        mb="8px"
                      >
                        First Name*
                      </FormLabel>
                      <Field name="firstName">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.firstName && form.touched.firstName
                            }
                          >
                            <Input
                              variant="auth"
                              fontSize="sm"
                              type="text"
                              placeholder="First Name"
                              fontWeight="bold"
                              borderRadius={5}
                              size="lg"
                              onKeyPress={acceptCharacters}
                              {...field}
                            />
                            <FormErrorMessage>
                              {form.errors.firstName}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="bold"
                        color={textColor}
                        mb="8px"
                      >
                        Last Name*
                      </FormLabel>
                      <Field name="lastName">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.lastName && form.touched.lastName
                            }
                          >
                            <Input
                              variant="auth"
                              fontSize="sm"
                              type="text"
                              placeholder="Last Name"
                              fontWeight="bold"
                              borderRadius={5}
                              onKeyPress={acceptCharacters}
                              size="lg"
                              {...field}
                            />
                            <FormErrorMessage>
                              {form.errors.lastName}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>
                  </Grid>
                  <VSeparator marginTop="20px" />
                  <Grid
                    templateColumns="repeat(2, 1fr)"
                    width="100%"
                    columnGap={10}
                  >
                    <GridItem>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="bold"
                        color={textColor}
                        mb="8px"
                      >
                        Contact Number*
                      </FormLabel>
                      <Field name="contactNumber">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.contactNumber &&
                              form.touched.contactNumber
                            }
                          >
                            <Input
                              variant="auth"
                              fontSize="sm"
                              type="text"
                              onKeyPress={acceptNumbers}
                              placeholder="Contact Number"
                              fontWeight="bold"
                              borderRadius={5}
                              size="lg"
                              {...field}
                            />
                            <FormErrorMessage>
                              {form.errors.contactNumber}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>

                    <GridItem>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="bold"
                        color={textColor}
                        mb="8px"
                      >
                        Alternate Contact Number
                      </FormLabel>
                      <Field name="alternateContactNumber">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.alternateContactNumber &&
                              form.touched.alternateContactNumber
                            }
                          >
                            <Input
                              variant="auth"
                              fontSize="sm"
                              // type="number"
                              onKeyPress={acceptNumbers}
                              placeholder="Alternate Contact Number"
                              fontWeight="bold"
                              borderRadius={5}
                              size="lg"
                              {...field}
                            />
                            <FormErrorMessage>
                              {form.errors.alternateContactNumber}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>

                  </Grid>

                  <VSeparator marginTop="20px" />
                  <Grid
                    templateColumns="repeat(2, 1fr)"
                    width="100%"
                    columnGap={10}
                  >
                    <GridItem>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="bold"
                        color={textColor}
                        mb="8px"
                      >
                        Gender*
                      </FormLabel>
                      <Field name="genderID">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.genderID &&
                              form.touched.genderID
                            }
                          >
                            <Select
                              fontSize="sm"
                              fontWeight="bold"
                              borderRadius={5}
                              size="lg"
                              {...field}
                            >
                              <option value="">--- Select ---</option>
                              {genderOptions.map((gender) => (
                                <option key={gender.value} value={gender.value}>
                                  {gender.label}
                                </option>
                              ))}

                            </Select>

                            <FormErrorMessage>
                              {form.errors.genderID}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>

                  </Grid>

                  <VSeparator marginTop="20px" />
                  <Grid
                    templateColumns="repeat(2, 1fr)"
                    width="100%"
                    columnGap={10}
                  >
                    <GridItem>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="bold"
                        color={textColor}
                        mb="8px"
                      >
                        Institute Email
                      </FormLabel>
                      <Field name="email">
                        {({ field, form }: any) => (
                          <FormControl>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              type="email"
                              placeholder="Email Id"
                              fontWeight="bold"
                              borderRadius={5}
                              size="lg"
                              {...field}
                              isReadOnly
                            />
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>

                    <GridItem>
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="bold"
                        color={textColor}
                        display="flex"
                      >
                        New Password*
                      </FormLabel>
                      <Field name="password" display="flex">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.password && form.touched.password
                            }
                          >
                            <InputGroup size="md">
                              <Input
                                fontSize="sm"
                                placeholder="Password"
                                size="lg"
                                type={showNewPassword ? 'text' : 'password'}
                                variant="auth"
                                borderRadius={5}
                                {...field}
                              />
                              <InputRightElement
                                display="flex"
                                alignItems="center"
                                mt="4px"
                              >
                                <Icon
                                  color={textColorSecondary}
                                  _hover={{ cursor: 'pointer' }}
                                  as={
                                    !showNewPassword
                                      ? RiEyeCloseLine
                                      : MdOutlineRemoveRedEye
                                  }
                                  onClick={() =>
                                    setShowNewPassword(!showNewPassword)
                                  }
                                />
                              </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>
                              {form.errors.password}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>

                  </Grid>
                  <Flex justifyContent={'center'}>
                    <Button
                      fontSize="sm"
                      colorScheme=""
                      variant="solid"
                      type="submit"
                      fontWeight="500"
                      Justify-content="center"
                      w="30%"
                      h="50"
                      marginTop="40px"
                      background="#32357C"
                      borderRadius={30}
                      disabled={!formik.isValid}
                      isLoading={loading}
                      loadingText="Submit"
                    >
                      Next
                    </Button>
                  </Flex>
                </Form>
              );
            }}
          </Formik>
        )}
      </Card>
    </AuthCentered>
  );
};
export default CreateUser;
