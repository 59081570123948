const initialState: any = {
    academicYearData: [],
    error: null,
};
type AcademicYearAction = {
    type: string,
    payload: any
};

const AcademicYearReducer = (state = initialState, action: AcademicYearAction) => {
    switch (action.type) {
        case 'ACADEMICYEAR_SUCCESS':
            return {
                ...state,
                academicYearData: action.payload,
                error: null,
            };
        case 'ACADEMICYEAR_FAILURE':
            return {
                ...state,
                academicYearData: null,
                error: action.payload,
            };
        default:
            return state;
    }
}
export default AcademicYearReducer;