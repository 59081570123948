import { Flex, FormLabel, Text } from '@chakra-ui/react';
import { ErrorMessage, useField } from 'formik';

interface Props {
  label: string;
  name: string;
  [x: string]: any;
  isChecked: boolean;
}

export const CustomCheckBox = (props: Props) => {
  const [field] = useField(props);
  let checked = Boolean(field.value);
  const isChecked = props?.isChecked;
  return (
    <>
      <Flex direction="column" my={'10px'}>
        <FormLabel className="label_check">
          <input
            type="checkbox"
            {...field}
            {...props}
            checked={checked}
            disabled={props.readOnly}
            onChange={props.onChange?.chgHandler ? (evt) => {
              props.onChange?.chgHandler(evt, props.onChange?.setFieldValue)
            } : field.onChange}
            style={{ marginRight: '10px' }}

          />
          <span>{props?.label}</span>
          <br />
          <ErrorMessage name={props.name} component="span" className="error" />
        </FormLabel>
      </Flex>
    </>
  );
};
