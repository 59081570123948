const initialState: any = {
    employeeInstitutesData: [],
    error: null
}

type EmployeeInstitutesAction = {
    type: string,
    payload: any
}

const EmployeeInstitutesReducer = (state = initialState, action: EmployeeInstitutesAction) => {
    switch (action.type) {
        case 'EMPLOYEEINSTITUTE_SUCCESS':
            return {
                ...state,
                employeeInstitutesData: action.payload,
                error: null
            };
        case 'EMPLOYEEINSTITUTE_FAILURE':
            return {
                ...state,
                employeeInstitutesData: null,
                error: action.payload,
            };

        default:
            return state;
    }
}
export default EmployeeInstitutesReducer