import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import {
  Button,
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useEffect, useState } from 'react';
import AdminLogin from 'views/auth/AdminLogin';
import ProtectedRoute from 'routes/ProtectedRoute';
import { PageNotFound } from 'views/common/PageNotFound';
import InstitutionForm from 'views/admin/Institutions/components/InstitutionForm';
import updatedEnv from 'assets/config_qa.json';
import { Environment } from 'services/Environment';
import { Expired } from 'views/common/Expired';
import CreateUser from 'views/auth/CreateUser';
import ForgotPassword from 'views/auth/ForgotPassword';
import NewUser from 'views/auth/NewUser';

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  const [envUpdated, setEnvUpdated] = useState(false)
  useEffect(() => {
    Object.keys(updatedEnv as any).map((x: string) => {
      (Environment as any)[x] = (updatedEnv as any)[x];
    });
    setEnvUpdated(true)
  }, [])
  return (

    <>
      {envUpdated &&
        <ChakraProvider theme={currentTheme}>
          <Routes>
            <Route path="/expired" element={<Expired />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/" element={<Navigate to="/admin/login" replace />} />
            <Route path="/newuser" element={<NewUser />} />
            <Route path="/:encryptedkey" element={<AdminLogin />} />
            <Route path="/createuser" element={<CreateUser />} />
            <Route path="/institutions" element={<InstitutionForm />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route element={<ProtectedRoute />}>
              <Route path="auth/*" element={<AuthLayout />} />
              <Route
                path="admin/*"
                element={
                  <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
                }
              />
              <Route
                path="rtl/*"
                element={
                  <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </ChakraProvider>
      }
    </>
  );
}
