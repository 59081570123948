import {
  Card,
  CardBody,
  CardHeader,
  Center,
  Image,
  Text,
} from '@chakra-ui/react';

export const PageNotFound = () => {
  return (
    <>
      <Card pt={30}>
        <Center>
          <CardHeader fontSize={30}>404 Error :(</CardHeader>
        </Center>
        <Center>
          <CardBody>
            <b>Oops,Page Not Found Please Enter Correct URL</b>
          </CardBody>
        </Center>
      </Card>
    </>
  );
};
