import {
  Flex,
  FormLabel,
  Select,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ErrorMessage, useField } from 'formik';

interface Props {
  options: Opt[];
  label: string;
  name: string;
  mb?: string;
  onChange?: any;
  readOnly?: any;
  [x: string]: any;
}

type Opt = { value: string | number; desc: string };

export const CustomSelect = ({ label, extra, ...props }: Props) => {

  const [field] = useField(props);

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  return (
    <>
      <Flex direction="column" mb={props.mb ? props.mb : '20px'}>
        <FormLabel
          display="flex"
          style={{ marginTop: '10px', marginBottom: '0px' }}
          ms="10px"
          fontSize="sm"
          color={textColorPrimary}
          fontWeight="bold"
          _hover={{ cursor: 'pointer' }}
          textTransform={'capitalize'}
        >
          {label}
          <Text fontSize="sm" fontWeight="400" ms="2px" mt={1} mb={1}>
            {extra}
          </Text>
        </FormLabel>
        <Select {...field} {...props} id={props.name} isDisabled={props.readOnly}
          onChange={props.onChange?.chgHandler ? (evt) => {
            props.onChange?.chgHandler(evt, props.onChange?.setFieldValue, props.onChange.otherProps)
          } : field.onChange}
          borderRadius="0.375rem">
          {props?.from === 'dynamic' ?
            (<>
              <option value="">--- Select ---</option>
              {props.options?.map((option: any) => (
                <option key={option.ParameterOptionID} value={option.ParameterOptionID}>
                  {option.Options}
                </option>
              ))}
            </>
            ) : (
              <>
                <option value="">--- Select ---</option>
                {props?.options?.map((option: any) => (
                  <option key={option.value} value={option.value}>
                    {option.desc}
                  </option>
                ))}
              </>
            )}
        </Select>
        <ErrorMessage name={props.name} component="span" className="error" />
      </Flex>
    </>
  );
};
