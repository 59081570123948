const initialState: any = {
    dropdownData: {},
    error: null,
};
type AuthAction = {
    type: string,
    payload: any
};

const DropDownReducer = (state = initialState, action: AuthAction) => {
    switch (action.type) {
        case 'DROPDOWN_DATA':
            return {
                ...state,
                dropdownData: { ...state.dropdownData, [action.payload.key]: action.payload.value },
                error: null,
            };
        case 'DROPDOWN_FAILURE':
            return {
                ...state,
                dropdownData: { ...state.dropdownData, [action.payload.key]: action.payload.value },
                error: action.payload,
            };
        default:
            return state;
    }
};
export default DropDownReducer;


