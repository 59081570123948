import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ErrorMessage, useField } from 'formik';
import '../../assets/css/App.css';

interface Props {
  id: string;
  name: string;
  type: string;
  label: string;
  extra?: JSX.Element;
  placeholder?: string;
  [x: string]: any;
}

export const CustomTextInput = (props: Props) => {
  const [field] = useField(props);
  const { id, value, label, extra, placeholder, type, my, onChange, ...rest } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  return (
    <>
      <Flex direction="column" mb={my ? my : '10px'}>
        <FormLabel
          display="flex"
          style={{ marginTop: '10px', marginBottom: '0px' }}
          textTransform={'capitalize'}
          ms="10px"
          htmlFor={id}
          fontSize="sm"
          color={textColorPrimary}
          fontWeight="bold"
          _hover={{ cursor: 'pointer' }}
        >
          {label}
          <Text fontSize="sm" fontWeight="400" ms="2px" mt={1} mb={1}>
            {extra}
          </Text>
        </FormLabel>

        <Input {...field} {...props} variant="outline" onKeyPress={props.keyPress} readOnly={rest.readOnly} onChange={onChange?.chgHandler ? (evt) => {
          onChange?.chgHandler(evt, onChange?.setFieldValue, onChange.otherProps)
        } : field.onChange} />
        <Text className="note">{rest.note}</Text>
        <ErrorMessage name={props.name} component="span" className="error" />
      </Flex>
    </>
  );
};
