import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Icon, Input, InputGroup, InputRightElement, useColorModeValue, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { VSeparator } from 'components/separator/Separator';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri'
import React from 'react';
import CommonToast from 'views/common/Toast';
import DefaultAuth from 'layouts/auth/variants/Default';
import instituteService from 'services/InstituteService';
import { Guid } from 'guid-typescript';
import { useLocation, useNavigate } from 'react-router-dom';
const NewUser = () => {
    const textColorSecondary = 'gray.400';
    const textColor = useColorModeValue('navy.700', 'white');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const loc = useLocation();
    const [mail, setMail] = useState('')
    const [firstName, setFirstName] = useState('')
    useEffect(() => {

        if (loc?.state) {
            const { emailID, firstName } = loc.state
            setMail(emailID)
            setFirstName(firstName)
        }
    }, []);

    const handleChangePwd = (formValues: any, actions: any) => {
        if (formValues.newPassword != formValues.confirmPassword) {
            const errorMessage = 'Confirm Password does not match with New Password';
            actions.setFieldError('confirmPassword', errorMessage);
            return
        }

        let payload = {
            emailID: mail,
            password: formValues.newPassword,
            isTemp: false
        }
        setLoading(true)
        instituteService.resetPassword(payload).then((res: any) => {
            setLoading(false)
            if (res?.status == 200) {
                if (res?.data == 1) {
                    navigate('/admin/login')
                    CommonToast({
                        title: 'Updated Successfully',
                        status: 'success',
                    });
                } else {
                    CommonToast({
                        description: 'Please try again later',
                        status: 'error',
                    })
                }
            }
        })

    }

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string().required('New Password is required').min(8, "You must enter at least 8 characters."),
        confirmPassword: Yup.string().required('Confirm Password is required').min(8, "You must enter at least 8 characters."),
    });

    return (<>
        <DefaultAuth >
            <Flex
                w='100%'
                maxW='max-content'
                mx={{ base: 'auto', lg: '0px' }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: '30px', md: '60px', lg: '100px', xl: '60px' }}
                px={{ base: '25px', md: '0px' }}
                mt={{ base: '40px', lg: '16vh', xl: '22vh' }}
                flexDirection='column'>
                <Box me='auto' mb='34px'>
                    <Heading color={textColor} fontSize={{ base: '3xl', md: '36px' }} mb='16px'>
                        Hello {firstName}
                    </Heading>

                    <Text color={textColor} fontSize='24px' mb='16px'>
                        Change Password
                    </Text>
                </Box>

                <Flex
                    zIndex='2'
                    direction='column'
                    w={{ base: '100%', lg: '456px' }}
                    maxW='100%'
                    mb={{ base: '20px', md: 'auto' }}
                >

                    <Formik
                        initialValues={{
                            newPassword: '',
                            confirmPassword: ''
                        }}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={handleChangePwd}>
                        <Form>


                            <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                                New Password *
                            </FormLabel>
                            <Field name="newPassword">
                                {({ field, form }: any) => (
                                    <FormControl
                                        isInvalid={
                                            form.errors.newPassword && form.touched.newPassword
                                        }
                                    >
                                        <InputGroup size="md">
                                            <Input
                                                // isRequired={true}
                                                fontSize="sm"
                                                placeholder="New Password"
                                                size="lg"
                                                type={showNewPassword ? 'text' : 'password'}
                                                variant="auth"
                                                {...field}
                                                background={'#FFF'}
                                                borderRadius={'5px'}
                                                autoComplete="on"
                                            />
                                            <InputRightElement
                                                display="flex"
                                                alignItems="center"
                                                mt="4px"
                                            >
                                                <Icon
                                                    color={textColorSecondary}
                                                    _hover={{ cursor: 'pointer' }}
                                                    as={!showNewPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                    onClick={() =>
                                                        setShowNewPassword(!showNewPassword)
                                                    }
                                                    mr={3}
                                                />
                                            </InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.newPassword}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <VSeparator marginTop="30px" />

                            <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                                Confirm Password *
                            </FormLabel>
                            <Field name="confirmPassword">
                                {({ field, form }: any) => (
                                    <FormControl
                                        isInvalid={
                                            form.errors.confirmPassword && form.touched.confirmPassword
                                        }
                                    >
                                        <InputGroup size="md">
                                            <Input
                                                // isRequired={true}
                                                fontSize="sm"
                                                placeholder="Confirm Password"
                                                size="lg"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                variant="auth"
                                                {...field}
                                                background={'#FFF'}
                                                borderRadius={'5px'}
                                                autoComplete="on"
                                            />
                                            <InputRightElement
                                                display="flex"
                                                alignItems="center"
                                                mt="4px"
                                            >
                                                <Icon
                                                    color={textColorSecondary}
                                                    _hover={{ cursor: 'pointer' }}
                                                    as={!showConfirmPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                                    onClick={() =>
                                                        setShowConfirmPassword(!showConfirmPassword)
                                                    }
                                                    mr={3}
                                                />
                                            </InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.confirmPassword}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <VSeparator marginTop="30px" />

                            <Button fontSize='sm' backgroundColor={'#11047A'}
                                variant={'darkBrand'} fontWeight='500' w='100%' h='50' mb='24px' type="submit"
                                isLoading={loading}>
                                Change Password
                            </Button>

                        </Form>
                    </Formik>
                </Flex>
            </Flex>
        </DefaultAuth >




    </>)
}
export default NewUser