// Chakra imports
import { Flex, FormLabel, Textarea, Text, useColorModeValue } from '@chakra-ui/react';
import { ErrorMessage, useField } from 'formik';
// Custom components

interface Props {
	id: string;
	name: string;
	type: string;
	label: string;
	extra?: JSX.Element;
	placeholder?: string;
	[x: string]: any;
}
export const CustomTextArea = (props: Props) => {
	const [field] = useField(props);
	const { mb, id, label, extra, placeholder, ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const bgPrimary = useColorModeValue('transparent', 'navy.800');
	const borderPrimary = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
	return (
		<Flex direction='column' mb={mb ? mb : '30px'}>
			<FormLabel
				display='flex'
				ms='10px'
				htmlFor={id}
				fontSize='sm'
				color={textColorPrimary}
				fontWeight='bold'
				_hover={{ cursor: 'pointer' }}>
				{label}
				<Text fontSize='sm' fontWeight='normal' ms='2px'>
					{extra}
				</Text>
			</FormLabel>
			<Textarea
				{...field}
				id={id}
				placeholder={placeholder}
				h='44px'
				maxH='44px'
				color={textColorPrimary}
				fontSize='sm'
				bg={bgPrimary}
				border='1px solid '
				borderColor={borderPrimary}
				borderRadius='8px'
				{...rest}
				_placeholder={{ color: 'secondaryGray.500' }}
			/>
			<ErrorMessage name={props.name} component="span" className="error" />
		</Flex>
	);
}
