// import { UserPrivileges } from "assets/data/privileges"

import instituteService from "services/InstituteService";

export const GetUserPrivileges = (roleID: any) => async (dispatch: any) => {
    try {

        const UserPrivileges = await instituteService.getPrivilegeByRole(roleID)
        let x = UserPrivileges?.data.map((i: any) =>
            i.privilegeName
        )
        console.log("UserPrivilegealssssss", x)
        dispatch({ type: 'USERPRIVILEGES_SUCCESS', payload: x });
    } catch (error: any) {
        dispatch({ type: 'USERPRIVILEGES_FAILURE', payload: error.message });
    }
}