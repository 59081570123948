import { Flex, FormLabel, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { useField, ErrorMessage } from 'formik';

type Opt = { value: string | number; desc: string };

interface Props {
  options: Opt[];
  name: string;
  label: string;
  [x: string]: any;
}

export const CustomRadioGroup = ({ label, my, options, ...props }: Props) => {
  const [field, , helpers] = useField(props);

  const handleChange = (value: string | number) => {
    helpers.setValue(value);
  };
  return (
    <Flex className="radio-group" direction="column" mb={my ? my : '10px'}>
      <b>{label}</b>
      <RadioGroup value={field.value} onChange={(value) => handleChange(value)}>
        <Stack direction='row'>
          {options.map((opt: any) => (
            <Radio size='md' value={opt?.value?.toString()} fontSize={'14px !important'}>{opt?.desc}</Radio>
          ))}
        </Stack>
      </RadioGroup>
      <ErrorMessage name={props.name} component="span" className="error" />
    </Flex>
  );
};
