export class Constants {
    public static readonly SocietyTrustRegistartionCopy = 502;
    public static readonly SocietyTrustBylaws = 503;
    public static readonly SocietyTrustMembersList = 504;
    public static readonly AffiliationDetails = 505;
    public static readonly InstituteLogo = 500;
    public static readonly StudentProfilePic = 501;

    // public static readonly StudentAadhaarCard = 5;
    // public static readonly FatherAadhaarCard = 6;
    // public static readonly MotherAadhaarCard = 7
    // public static readonly GuardianAadharCard = 8;
    // public static readonly TransferCertificate = 9;
    // public static readonly CasteCertificate = 10;
    // public static readonly ImmunizationRecords = 11;
    // public static readonly StudyCertificate = 12;
    // public static readonly LatestMarksheet = 13;
    // public static readonly BirthCertificate = 14;

    // public static readonly studentphoto = 16;
    // public static readonly fatherphoto = 17;
    // public static readonly Motherphoto = 18;
    public static readonly version1 = 'v1/';
    public static readonly new_instituteStatusID = 204;
    public static readonly active_instituteStatusID = 205;
    public static readonly msg_LinkExpired = 'Link Expired';
    public static readonly settingsArray = [75];
    public static readonly settingsPrivilege = 1;
    public static readonly branchSettingsArray = [5, 8, 11, 14, 17, 19, 22, 25, 28, 30, 34, 37, 41, 44]
    public static readonly branchSettingsPrivilege = 4;
    public static readonly admissionsArray = [47, 54];
    public static readonly admissionsPrivilege = 46;
    public static readonly manageStudentsArray = [58];
    public static readonly manageStudentsPrivilege = 57;
    public static readonly manageEmployeeArray = [62, 66];
    public static readonly manageEmployeePrivilege = 61;
    public static readonly manageUserArray = [69];
    public static readonly manageUserPrivilege = 68;
    public static readonly utilityArray = [73, 74];
    public static readonly utilityPrivilege = 72;
    public static readonly parentPrivilegeArray = [1, 4, 46, 57, 61, 68, 72];
}
